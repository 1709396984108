import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";
import CustomLink from "../components/CustomLink";


class BioCard extends React.Component {
  setAvatarLetter(str) {
    let firstLetters = str.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');
    return firstLetters;
  }

  render() {
    const person = this.props.person;

    return (
      <div className="team-member-card mgb-m flex-row
                                             s-flex-column s-justc-start s-alitems-start">
        <div className="avatar mgr-s s-mgb-s">
          {(person.avatar && person.avatar.length > 1 ?
            <div className="avatar-picture" style={{ backgroundImage: `url(${person.avatar})` }}></div>
            : <div className="avatar-letters bold-weight">{this.setAvatarLetter(person.name)}</div>)}
        </div>
        <div className="description full-width flex-column flex-1 justc-start alitems-start">
          <h3 className="title-5 bold-weight text-light">{person.name}</h3>
          <ReactMarkdown children={person.description}/>
          <div className="links flex-row justc-start mgt-s">
            {(person.website && person.website.length > 1 ?
              <CustomLink Type="external-blank"
                linkURL={person.website}
                className="bio-link">
                <img src="/img/icons/website-logo.svg" alt="website icon" />
              </CustomLink>
              : '')}
            {(person.twitter && person.twitter.length > 1 ?
              <CustomLink Type="external-blank"
                linkURL={person.twitter}
                className="bio-link">
                <img src="/img/icons/twitter-logo.svg" alt="website icon" />
              </CustomLink>
              : '')}
            {(person.git && person.git.length > 1 ?
              <CustomLink Type="external-blank"
                linkURL={person.git}
                className="bio-link">
                <img src="/img/icons/git-logo.svg" alt="git icon" />
              </CustomLink>
              : '')}
            {(person.linkedin && person.linkedin.length > 1 ?
              <CustomLink Type="external-blank"
                linkURL={person.linkedin}
                className="bio-link">
                <img src="/img/icons/linkedin-logo.svg" alt="linkedin icon" />
              </CustomLink>
              : '')}
          </div>
        </div>
      </div>
    )
  }
}


export const EquipePageTemplate = ({page}) => {
  return (
    <div className="section">
      <div className="content-wrapper">
        <h1 className="title-3 text-medium bold-weight center-text">{page.frontmatter.title}</h1>
        <div className="team-member-list flex-row justc-space-b mgt-xl
                                         m-flex-column m-alitems-center m-mgt-l s-mgt-m">
          <div className="team-column left-column half-width flex-column mgr-s">
            {page.frontmatter.people.map((person, index) => (
              (index%2 ? '':
              <BioCard key={index} person={person}/>)
            ))}
          </div>
          <div className="team-column right-column flex-column mgl-s">
            {page.frontmatter.people.map((person, index) => (
              (index%2 ? 
                <BioCard key={index} person={person}/>
              :'')
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const EquipePage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page; 

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <EquipePageTemplate page={page} />
    </Layout>
  );
};

EquipePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EquipePage;

export const equipePageQuery = graphql`
  query EquipePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        people {
          name
          avatar
          description
          linkedin
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
